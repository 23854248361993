import React, {useState, useEffect} from 'react';
import Breadcrumb from "../components/Breadcrumb";
import {useNavigate, useSearchParams} from 'react-router-dom';
import {
    SdfTab,
    SdfTabGroup,
    SdfFocusPane,
    SdfPromoBox,
    SdfIcon,
    SdfxPagination,
    SdfSelectSimple,
    SdfBadge,
} from '@synerg/react-components';
import {categoryList, highlightText} from '../utility/searchUtil';
import SearchArticleContent from '../components/articles/SearchArticleContent';
import {ARTICLES_BORDER_BOTTOM, ARTICLES_BORDER_RIGHT} from "../utility/constants";
import {articlesApiTransformByType} from "../utility/transform/articlesApi";
import {NoData} from "../components/NoData";
import {InputWidthEnum} from "../utility/enums/InputWidthEnum";
import {ArticleTags} from "../components/articles/ArticlesTags";
import {useSelector} from "react-redux";
import {ViewArticleOrLogIn} from "../components/articles/ViewArticleOrLogIn";
import UseCasePage from "./Build/IntegrationsBySolution/UseCasePage";
// import '../components/articles/previews/GuidesPrintView/ChapterPrintView/style.css';
import {LoadHtmlFromString} from "../components/LoadHtmlFromString";
import {BusyPageIndicator} from "../components/BusyPageIndicator";

const HOVER_ROW = 'hover:bg-action-default-100 cursor-pointer border-b-2 border-dark';
const rowsPerPageData = [{label: 'Rows per page: 10', value: "10"}, {label: 'Rows per page: 20', value: "20"}]

function ViewSearchResults() {
    const [selectedPreview, setSelectedPreview] = useState();
    const [searchResponse, setSearchResponse] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [currentTab, setCurrentTab] = useState(categoryList.all)
    const [currentPage, setCurrentPage] = useState("1")
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const searchQuery = searchParams.get('query');
    const size = searchParams.get('size');
    const tabId = searchParams.get('id');
    const findPreviewFocusPane = () => document.getElementById('article-preview-pane');

    let breadcrumbPath = [{name: 'Welcome', route: '/welcome'}, {name: 'Search results'}];

    let searchList;
    if (findPreviewFocusPane()) {
        const focusPane = findPreviewFocusPane()
        focusPane.addEventListener('sdfDismiss', (evt) => closeModal());
    }

    useEffect(() => {
        const activeTab = Object.values(categoryList).find(tab => tab.id === tabId)
        tabChange(activeTab ?? categoryList.all);
    }, [searchQuery])


    const {isLoggedIn} = useSelector(({auth}) => {
        const {isLoggedIn} = auth
        return {isLoggedIn};
    });

    const tabChange = async (tab) => {
        setIsLoading(true)
        setCurrentTab(tab)
        setCurrentPage("1")
        Object.values(categoryList).forEach(category => category.id === tab.id ? category.isActive = true : category.isActive = false);
        setSearchParams({
            query: searchQuery,
            type: tab.type,
            tags: tab.tags,
            index: tab.index,
            id: tab.id,
            size,
            from: 0
        })

        const {isError, articles, total} = await categoryList[tab.id].service({
            searchQuery,
            guideType: tab.type,
            tag: tab.tags,
            index: tab.index,
            size: size,
            from: 0
        })
        setSearchResponse({
            isError,
            articles,
            total
        })
        //const totalPages = Math.ceil(searchResponse?.total / 10)

        setIsLoading(false)
    }

    const sliceContent = (str) => {
        return str && str.length > 200 ? str.slice(0, 200) + '...' : str;
    }

    const showPreview = (article) => {
        setSelectedPreview(article);
        findPreviewFocusPane().open();
    }

    const closeModal = () => {
        findPreviewFocusPane().close();
    }

    const viewContent = () => {
        navigate(selectedPreview?.navigationPath);
    }

    const onChangePage = async (e) => {
        setIsLoading(true)
        setCurrentPage(e.detail)
        const from = (e.detail - 1) * rowsPerPage;

        setSearchParams({
            query: searchQuery,
            type: currentTab.type,
            tags: currentTab.tags,
            index: currentTab.index,
            id: currentTab.id,
            from,
            size
        })

        const {isError, articles, total} = await categoryList[currentTab.id].service({
            searchQuery,
            guideType: currentTab.type,
            tag: currentTab.tags,
            index: currentTab.index,
            from,
            size: rowsPerPage
        })


        setSearchResponse({
            isError,
            articles,
            total
        })

        setIsLoading(false)
    }

    const onChangeRowsPerPage = async (e) => {
        setIsLoading(true)
        const numberOfRows = e.detail.value
        setRowsPerPage(numberOfRows)
        setCurrentPage("1")
        const from = searchParams.get('from')
        setSearchParams({
            query: searchQuery,
            type: currentTab.type,
            tags: currentTab.tags,
            index: currentTab.index,
            id: currentTab.id,
            from: 0,
            size: numberOfRows
        })

        const {isError, articles, total} = await categoryList[currentTab.id].service({
            searchQuery,
            guideType: currentTab.type,
            tag: currentTab.tags,
            index: currentTab.index,
            from,
            size: numberOfRows
        })


        setSearchResponse({
            isError,
            articles,
            total
        })

        setIsLoading(false)
    }

    const findRowsPerPageValue = () => {
        return rowsPerPageData.find(row => row.value === size)
    }


    if (isLoading) {
        searchList = <BusyPageIndicator positionClass="list-loading"/>
    } else if (searchResponse.isError) {
        searchList = <h3>Error loading Products</h3>
    } else if (searchResponse?.articles.length === 0) {
        searchList = <NoData/>
    } else {
        searchList =
            <>
                {searchResponse?.articles?.map((article, index) => {
                    article = articlesApiTransformByType(article)

                    return <div key={index} className={HOVER_ROW} onClick={() => showPreview(article)}>
                        <section
                            className={`flex justify-between items-center py-4 px-2 text-md ${ARTICLES_BORDER_BOTTOM}`}>
                            <main className="w-3/4 flex">
                                <div className="">
                                    <LoadHtmlFromString htmlStr={highlightText(sliceContent(article?.title), searchQuery)} className="text-action-primary text-lg"/>

                                    <LoadHtmlFromString htmlStr={highlightText(sliceContent(article?.abstract), searchQuery)} className="py-2"/>
                                    <ArticleTags article={article}/>
                                </div>
                            </main>
                            <aside className="flex text-action-primary">
                                <div className="min-w-5 text-action-primary -mt-px mr-1">
                                    {!article?.isPublic && !isLoggedIn &&
                                        <SdfIcon icon='status-locked'/>}
                                </div>
                                Preview
                            </aside>
                        </section>
                    </div>
                })}
                <div className="flex justify-end pt-6 text-xs">
                    <SdfSelectSimple
                        className="flex"
                        inputWidth_md={InputWidthEnum.MD}
                        value={findRowsPerPageValue()}
                        emphasis="tertiary"
                        items={rowsPerPageData}
                        onSdfChange={onChangeRowsPerPage}
                    />

                    <div className={`${ARTICLES_BORDER_RIGHT}`}></div>
                    <SdfxPagination
                        className="pl-3"
                        currentPage={currentPage}
                        totalPages={Math.ceil(searchResponse?.total / size)}
                        onSdfxPageChange={async (e) => await onChangePage(e)}
                    />

                </div>

            </>
    }

    const title =
        <header className='text-3xl py-4'>
            <span className="font-thin">Search Results ({searchResponse?.total}): </span>
            <span className="font-medium">{searchQuery}</span>
        </header>

    const tabs =
        <SdfTabGroup>
            {Object.values(categoryList).map((ele, i) => (
                <SdfTab key={i} value={ele.id} active={ele.isActive} onClick={() => tabChange(ele)}>{ele.label}</SdfTab>
            ))}
        </SdfTabGroup>

    const modal =
        <SdfFocusPane
            id="article-preview-pane"
            dismissOnClickOutside
            hideAcceptButton
            closeable
            hideDismissButton
            size="xl"
            style={{zIndex: 9999}}
        >
            {
                selectedPreview &&
                <aside className="px-8">
                    {
                        selectedPreview?._source?.title &&
                        <div className="flex justify-between items-end mb-6">
                            <h1 className="text-4xl font-black m-0" tabIndex="0">
                                {selectedPreview?.title}
                            </h1>
                            <div className="flex items-end">
                                <SdfBadge status="new">
                                    Preview
                                </SdfBadge>
                            </div>
                        </div>
                    }
                    {
                        (selectedPreview?.abstract && !['API', 'USECASE'].includes(selectedPreview?.tagName)) &&
                        <SdfPromoBox
                            layout="horizontal"
                            variant="filled"
                            illustration-name="documents"
                            header="Summary"
                            message={selectedPreview?.abstract}
                        />
                    }
                    <div className="flex flex-col break-words">
                        <div className="flex flex-col">
                            {(selectedPreview?.isPublic || isLoggedIn) &&
                                <span>
                                    {
                                        selectedPreview?.tagName === 'USECASE' ?
                                            <div className="mt-3">
                                                <UseCasePage
                                                    id={selectedPreview?._source.paths[0].split('/').pop()}
                                                    showBreadcrumb={false}
                                                />
                                            </div> :
                                            <LoadHtmlFromString
                                                htmlStr={selectedPreview?._source?.content}
                                                className="mb-2 mr-10 overflow-x-auto"
                                            />

                                    }
                                    <SearchArticleContent
                                        section={selectedPreview?._source}
                                        highlightText={(text) => highlightText(text)}
                                        apiClick={() => viewContent()}
                                        swaggerUrl={selectedPreview?._source?.swagger}
                                    />
                                </span>
                            }
                        </div>
                    </div>
                    {
                        (!selectedPreview?.isPublic && !isLoggedIn) &&
                        <div className="flex my-3 pt-8 flex-col justify-center items-center">
                            <SdfIcon icon='status-locked' external/>
                            <div className="pt-4">Please log in to view content</div>
                        </div>
                    }
                </aside>
            }
            <ViewArticleOrLogIn
                isPublic={selectedPreview?.isPublic}
                isLoggedIn={isLoggedIn}
                viewContent={viewContent}
                navigationPath={selectedPreview?.navigationPath}
            />
        </SdfFocusPane>

    return (
        <div className="px-8">
            <Breadcrumb path={breadcrumbPath}/>
            {title}
            {tabs}
            {searchList}

            {modal}
        </div>
    );
}

export default ViewSearchResults;