import React from "react"
import { useNavigate } from "react-router-dom";

import { SdfButton, SdfAlert, SdfIcon, SdfLink} from "@synerg/react-components"
import { PageTitle } from "../PageTitle"

import groupPic from '../../assets/images/Group2.png'

export function HeroHeader() {
    const navigate = useNavigate();

    const navigateGuide = () => {
        navigate("/build/guides/marketplace-integration-guides/Making-Your-First-API-Call-With-POSTMAN")
    }

    return (
        <>
            <div className="flex drop-shadow-xl">
                <div className="w-3/12" style={{ background: "rgb(18, 28, 78)", clipPath: "polygon(0 0, 100% 0, 92% 100%, 100% 100%, 0 100%)" }}>
                </div>
                <SdfAlert className="w-9/12" status="" type="banner" size={null}>
                    <SdfIcon icon="feedback-like" slot="icon" style={{ color: "#f4746c" }} className="lg:text-3xl md:text-lg m-6 mr-3" />
                    <p slot="title" className="font-bold lg:text-lg md:text-sm pb-4 pr-5">Help us improve our developer experience and earn a $25 e-gift card by taking a short survey.</p>
                    <SdfButton emphasis='secondary' slot="action" className="mr-16 lg:text-lg md:text-sm text-right lg:pr-5 pb-4">
                        <SdfLink target="_blank" href="https://adpuserexperience.qualtrics.com/jfe/form/SV_aVMd5ejhQsJ8bvU" 
                        className="cursor-pointer no-underline hover:underline text-action-primary">Start survey</SdfLink>
                    </SdfButton>
                </SdfAlert>
            </div>
        <div className="bg-gray-50 pl-4 md:pl-5 lg:pl-5 pr-8">
                <main className="flex lg:justify-between flex-col md:flex-row md:items-center lg:items-center md:pl-8 md:mr-24">
                    <section className="mt-4 md:mt-0">
                        <div>
                            <PageTitle
                                className="text-heading-08 font-black"
                                noMargin
                                id="heroHeader"
                            >
                                Integration made<br/> possible
                            </PageTitle>
                        </div>
                        <div className="pt-3" id="heroDescription">
                            Discover resources to help you build <br/>applications and connectors
                            using ADP® APIs
                        </div>

                        <SdfButton onClick={() => navigateGuide()}
                                   emphasis="primary" className="mt-4" id="getStarted1">Get started</SdfButton>
                    </section>
                    <section>
                        <img
                            src={groupPic}
                            alt='Group of people'
                            className="w-full lg:w-lg lg:my-10 md:w-sm md:my-4 md:my-0"
                            id="heroImage"
                        />
                    </section>
                </main>
            </div>
        </>
    )
}